/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class SalesNoteStatusEnum.
* @enum {}
* @readonly
*/
export default class SalesNoteStatusEnum {
    
        /**
         * value: "電話不通"
         * @const
         */
        "電話不通" = "電話不通";

    
        /**
         * value: "日程調整中"
         * @const
         */
        "日程調整中" = "日程調整中";

    
        /**
         * value: "アポ取得済み"
         * @const
         */
        "アポ取得済み" = "アポ取得済み";

    
        /**
         * value: "初回面談完了"
         * @const
         */
        "初回面談完了" = "初回面談完了";

    
        /**
         * value: "申込完了"
         * @const
         */
        "申込完了" = "申込完了";

    
        /**
         * value: "契約完了"
         * @const
         */
        "契約完了" = "契約完了";

    
        /**
         * value: "ローン審査通過"
         * @const
         */
        "ローン審査通過" = "ローン審査通過";

    
        /**
         * value: "決済完了"
         * @const
         */
        "決済完了" = "決済完了";

    
        /**
         * value: "断念"
         * @const
         */
        "断念" = "断念";

    
        /**
         * value: "短期追客"
         * @const
         */
        "短期追客" = "短期追客";

    
        /**
         * value: "長期追客"
         * @const
         */
        "長期追客" = "長期追客";

    

    /**
    * Returns a <code>SalesNoteStatusEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/SalesNoteStatusEnum} The enum <code>SalesNoteStatusEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

