import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: "#424750",
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          height: "37px",
          padding: "8px 16px",
          "&:first-of-type": {
            borderTopLeftRadius: "999px",
            borderBottomLeftRadius: "999px",
          },
          "&:last-of-type": {
            borderTopRightRadius: "999px",
            borderBottomRightRadius: "999px",
          },
          "&.Mui-selected": {
            fontWeight: 700,
            color: "#FFF",
            backgroundColor: "#424750",
            "&:hover": {
              backgroundColor: "#424750",
            },
          },
        },
      },
    },
  },
});
