/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import InvestmentProposalAttachmentFile from './InvestmentProposalAttachmentFile';
import ProposalStatusEnum from './ProposalStatusEnum';
import User from './User';

/**
 * The InvestmentProposal model module.
 * @module model/InvestmentProposal
 * @version 1.0.0
 */
class InvestmentProposal {
    /**
     * Constructs a new <code>InvestmentProposal</code>.
     * @alias module:model/InvestmentProposal
     */
    constructor() { 
        
        InvestmentProposal.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>InvestmentProposal</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InvestmentProposal} obj Optional instance to populate.
     * @return {module:model/InvestmentProposal} The populated <code>InvestmentProposal</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InvestmentProposal();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('agent_id')) {
                obj['agent_id'] = ApiClient.convertToType(data['agent_id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('proposal_content')) {
                obj['proposal_content'] = ApiClient.convertToType(data['proposal_content'], 'String');
            }
            if (data.hasOwnProperty('is_user_read')) {
                obj['is_user_read'] = ApiClient.convertToType(data['is_user_read'], 'Boolean');
            }
            if (data.hasOwnProperty('is_user_approved')) {
                obj['is_user_approved'] = ApiClient.convertToType(data['is_user_approved'], 'Boolean');
            }
            if (data.hasOwnProperty('proposal_date')) {
                obj['proposal_date'] = ApiClient.convertToType(data['proposal_date'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('user_read_at')) {
                obj['user_read_at'] = ApiClient.convertToType(data['user_read_at'], 'Date');
            }
            if (data.hasOwnProperty('user_approved_at')) {
                obj['user_approved_at'] = ApiClient.convertToType(data['user_approved_at'], 'Date');
            }
            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('investment_proposal_attachment_files')) {
                obj['investment_proposal_attachment_files'] = ApiClient.convertToType(data['investment_proposal_attachment_files'], [InvestmentProposalAttachmentFile]);
            }
            if (data.hasOwnProperty('proposal_status')) {
                obj['proposal_status'] = ProposalStatusEnum.constructFromObject(data['proposal_status']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>InvestmentProposal</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>InvestmentProposal</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['proposal_content'] && !(typeof data['proposal_content'] === 'string' || data['proposal_content'] instanceof String)) {
            throw new Error("Expected the field `proposal_content` to be a primitive type in the JSON string but got " + data['proposal_content']);
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `user`
        if (data['user']) { // data not null
          User.validateJSON(data['user']);
        }
        if (data['investment_proposal_attachment_files']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['investment_proposal_attachment_files'])) {
                throw new Error("Expected the field `investment_proposal_attachment_files` to be an array in the JSON data but got " + data['investment_proposal_attachment_files']);
            }
            // validate the optional field `investment_proposal_attachment_files` (array)
            for (const item of data['investment_proposal_attachment_files']) {
                InvestmentProposalAttachmentFile.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
InvestmentProposal.prototype['id'] = undefined;

/**
 * @member {Number} agent_id
 */
InvestmentProposal.prototype['agent_id'] = undefined;

/**
 * @member {Number} user_id
 */
InvestmentProposal.prototype['user_id'] = undefined;

/**
 * @member {String} proposal_content
 */
InvestmentProposal.prototype['proposal_content'] = undefined;

/**
 * @member {Boolean} is_user_read
 */
InvestmentProposal.prototype['is_user_read'] = undefined;

/**
 * @member {Boolean} is_user_approved
 */
InvestmentProposal.prototype['is_user_approved'] = undefined;

/**
 * @member {Date} proposal_date
 */
InvestmentProposal.prototype['proposal_date'] = undefined;

/**
 * @member {Date} created_at
 */
InvestmentProposal.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
InvestmentProposal.prototype['updated_at'] = undefined;

/**
 * @member {Date} user_read_at
 */
InvestmentProposal.prototype['user_read_at'] = undefined;

/**
 * @member {Date} user_approved_at
 */
InvestmentProposal.prototype['user_approved_at'] = undefined;

/**
 * @member {module:model/Agent} agent
 */
InvestmentProposal.prototype['agent'] = undefined;

/**
 * @member {module:model/User} user
 */
InvestmentProposal.prototype['user'] = undefined;

/**
 * @member {Array.<module:model/InvestmentProposalAttachmentFile>} investment_proposal_attachment_files
 */
InvestmentProposal.prototype['investment_proposal_attachment_files'] = undefined;

/**
 * @member {module:model/ProposalStatusEnum} proposal_status
 */
InvestmentProposal.prototype['proposal_status'] = undefined;






export default InvestmentProposal;

